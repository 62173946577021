export enum AccessType {
  None = 0,
  User = 100,
  UserType = 200,
  Address = 300,
  Settings = 500,
  Attachment = 600,
  Auth = 700,
  UserOptions = 800,
  Role = 900,
  TestGroup = 1000,
  TestCategory = 1100,
  Disease = 1200,
  Symptom = 1300,
  TestRule = 1400,
  TestRuleUnit = 1500,
  TestRuleSuggestion = 1600,
  Test = 2000,
  // PriorityExclusion = 2100,
  Priority = 2200,
  TestProperty = 2300,
  Meta = 2400,
  DiseaseRule = 2600,
  CdssTestUnit = 2700,
  Lab = 3000,
  LabBranch = 3100,
  TestProfile = 3200,
  AiPrompt = 3300,
  SubSymptom = 3400,
  UserCredits = 3700,
  Organization = 3800,
  Insurance = 3900,
  Feature = 4000,
  OrgFeatures = 4100,
  ChildFeature = 4200,
  ChildFeaturePrompt = 4300
}
