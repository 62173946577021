import { Injectable } from '@angular/core';
import { Role } from 'src/app/role/models/role.model';
import { Access } from '../interfaces/access.interface';
import { BaseService } from './base.service';
import { AppHttpService } from './app-http.service';

@Injectable({
  providedIn: 'root',
})
export class AccessService{
  allAccess: Access[] = [];
  userAccesses: number[] = [];

  constructor(private httpService: AppHttpService){}

  setAccess(accesses: Access[]) {
    this.allAccess = accesses;
  }

  setUserAccess(userRoles: Role[]) {
    const accesses = userRoles.reduce((prev, current) => {
      return [...prev, ...current.accessIds];
    }, [] as number[]);
    this.userAccesses = [...new Set(accesses)];
    this.httpService.setUserAccess(this.userAccesses);
    console.log(this.userAccesses);
  }

  /**
   * Check User access
   * @param access
   * @returns
   */
  checkAccess(access: number | number[]) {
    const isArray = Array.isArray(access);
    if (isArray)
      return access.some((accessId) => this.userAccesses.includes(accessId));
    return this.userAccesses.includes(access);
  }
}
